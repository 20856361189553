import { Stack } from '@mui/material';
import { getChooseAccountState, getTradingAmount } from 'entities/choose-account';
import { getAlertSource, getChooseSourceState } from 'entities/choose-source';
import { getRiskManagmentAdditionalState } from 'entities/risk-managment-additional';
import { getRiskManagmentAutoState } from 'entities/risk-managment-auto';
import { getRiskManagmentCloseState } from 'entities/risk-managment-close';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { MainButton, Title } from 'shared/ui';
import { createBot, updateBot } from '../api';
import { BotName } from '../components';
import { getActiveTradingPairsError, getTradingAmountError } from '../helpers';
import { ICreateBot } from '../interfaces';
import { TITLE, WRAPPER } from '../styles';
import { ChooseAccount, ChooseSource, ConfigureRiskManagment, CreateAlertMessage } from '../views';

export const Configurator = () => {
  const tradingAmount = useSelector(getTradingAmount);
  const alertSource = useSelector(getAlertSource);

  const chooseAccountState = useSelector(getChooseAccountState);
  const chooseSourceState = useSelector(getChooseSourceState);
  const additionalEntryOrdersState = useSelector(getRiskManagmentAdditionalState);
  const closeOrdesState = useSelector(getRiskManagmentCloseState);
  const autoState = useSelector(getRiskManagmentAutoState);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const isStrategy = alertSource === 'strategy';

  const navigate = useNavigate();
  const {
    id, 
  } = useParams();

  const handleNameModalState = () => {
    setIsModalOpened(true);
  };

  const createBotHandler = async () => {
    console.log({
      chooseAccountState,
      chooseSourceState,
      additionalEntryOrdersState,
      closeOrdesState,
      autoState,
    });

    const isBaseOrderError = chooseAccountState.market.minNotional > (autoState.values.baseOrderSize || 0);
    console.log('chooseAccountState.market.minNotional', chooseAccountState.market.minNotional, isBaseOrderError);
    if (isBaseOrderError) {
      toast.error('Order size is below the minimum');
      return;
    }

    const params: ICreateBot = {
      name: chooseAccountState.name as string,
      skyrexUserUuid: getSkyrexUuid() as string,
      exchangeAccountUuid: chooseAccountState.exchange.id,
      botType: 'CUSTOM',
      allowSharing: 'FALSE',
      exchangeCode: chooseAccountState.exchange.code,
      quote: chooseAccountState.market.code,
      minInvestment: 0,
      copyCommissionPercent: 0,
      copyCommissionFlat: 0,
      tradingAmount: +chooseAccountState.tradingAmount,
      maxActiveDeals: +chooseAccountState.activeTradingPairs,
      martingaleVolumeCoefficient: 1,
      maxSafetyOrders: 0,
      priceDeviationPercentSafety: 0,
      martingalePriceStepCoefficient: 0,
      strategy: 'LONG',
      baseOrderType: chooseSourceState.orderType,
      baseOrderConditionalTriggerType: 'LAST',
      additionalBaseOrderType: null,
      additionalBaseConditionalTriggerType: 'LAST',
      takeProfitPriceRecalculation: 'FROM_AVERAGE',
      takeProfitType: 0,
      takeProfitConditionalTriggerType: 'LAST',
      takeProfitPricePercent: 0,
      takeProfitPriceStepCoefficient: 1,
      takeProfitTargetsQuantity: 0,
      takeProfitVolumeStepCoefficient: 1,
      stopLossPriceRecalculation: 'FROM_AVERAGE',
      stopLossType: 0,
      stopLossConditionalTriggerType: 'LAST',
      stopLossPercent: 0,
      alertAdditionalBaseOrderType: null,
      alertCloseType: null,
      signalSource: chooseSourceState.alertSource,
    };

    if (additionalEntryOrdersState.isOpened && additionalEntryOrdersState.segment.value === 'preset') {
      const preset = additionalEntryOrdersState.segment.preset;

      params.additionalBaseOrderType = preset.orderType;
      params.martingaleVolumeCoefficient = preset.ordersSizeScale;
      params.maxSafetyOrders = preset.orders;
      params.priceDeviationPercentSafety = preset.priceChange;
      params.martingalePriceStepCoefficient = preset.ordersPriceChangeScale;
    }

    if (additionalEntryOrdersState.isOpened && additionalEntryOrdersState.segment.value === 'alert') {
      const alert = additionalEntryOrdersState.segment.alert;

      params.alertAdditionalBaseOrderType = alert.orderType;
      params.martingaleVolumeCoefficient = alert.ordersSizeScale;
      params.maxSafetyOrders = alert.orders;
    }

    const isCloseOrdersPreset = closeOrdesState.segment.value === 'preset';
    if (!isCloseOrdersPreset) {
      params.alertCloseType = closeOrdesState.segment.alert.orderType;
    }

    if (isCloseOrdersPreset && closeOrdesState.segment.preset.takeProfit.isOpened) {
      const takeProfit = closeOrdesState.segment.preset.takeProfit;

      params.takeProfitType = takeProfit.orderType;
      params.takeProfitPricePercent = takeProfit.priceChange;
      params.takeProfitPriceStepCoefficient = takeProfit.orderPriceChangeScale;
      params.takeProfitTargetsQuantity = takeProfit.orders;
      params.takeProfitVolumeStepCoefficient = takeProfit.orderSizeScale;
    }

    if (isCloseOrdersPreset && closeOrdesState.segment.preset.stopLoss.isOpened) {
      const stopLoss = closeOrdesState.segment.preset.stopLoss;

      params.stopLossType = stopLoss.orderType;
      params.stopLossPercent = stopLoss.priceChange;
    }

    if (autoState.segment === 'auto') {
      params.settingsType = autoState.preset;

      params.martingaleVolumeCoefficient = autoState.values.additionalOrderSizeScale;
      params.maxSafetyOrders = autoState.values.maxAdditionalOrders;
      params.priceDeviationPercentSafety = autoState.values.additionalOrderPriceChange;
      params.martingalePriceStepCoefficient = autoState.values.additionalOrderPriceChangeScale;
      params.takeProfitPricePercent = autoState.values.taketProfitPercent;
      params.stopLossPercent = autoState.values.stopLossPercent;
      params.additionalBaseOrderType = 'CONDITIONAL_MARKET';
      // params.baseOrderType = 'CONDITIONAL_MARKET';
      params.alertAdditionalBaseOrderType = 'MARKET';
      params.stopLossType = 'CONDITIONAL_MARKET';
      params.takeProfitType = 'CONDITIONAL_MARKET';
    }

    if (id) {
      params.botUuid = id;
    }
    
    const response = await (id ? updateBot(params) : createBot(params));
    if (!response.success) {
      toast.error(response.data.message);
      return;
    }

    navigate(`/trading-bots/my-bots/bot/${response.data.fields.botUuid}`);
  };
  
  const tradingAmountError = getTradingAmountError({
    amount: +tradingAmount,
    minNotional: chooseAccountState.market.minNotional,
  });

  const activeTradingPairsError = getActiveTradingPairsError({
    tradingAmount: +tradingAmount,
    activeTradingPairs: chooseAccountState.activeTradingPairs,
    minNotional: chooseAccountState.market.minNotional,
  });

  const additionalError = additionalEntryOrdersState.error;
  const closeOrdersError = closeOrdesState.error;
  const autoError = autoState.error;

  return (
    <Stack sx={WRAPPER}>
      <Title styles={TITLE}>
        Alert bot configurator
      </Title>

      <ChooseAccount />

      {(!!tradingAmount && !(tradingAmountError || activeTradingPairsError)) && (
        <Fragment>
          <ChooseSource />
    
          <ConfigureRiskManagment />
    
          {isStrategy && (
            <CreateAlertMessage />
          )}

          <MainButton
            type='primary'
            size='large'
            styles={{
              maxWidth: 400,
            }}
            onClick={handleNameModalState}
            disabled={!!(additionalError || closeOrdersError || autoError)}
          >
            {id ? 'Update' : 'Create'} Bot
          </MainButton>
        </Fragment>
      )}

      <BotName
        isModalOpened={isModalOpened}
        handleNameModalState={setIsModalOpened}
        createBotHandler={createBotHandler}
      />
    </Stack>
  );
};
