import { IRiskManagmentClose } from 'entities/risk-managment-close/interfaces';
import { BotData } from 'pages/trading-bots/my-bot/ui/my-bot';

export const getCloseOrdersValue = (bot: BotData) => {
  const value: IRiskManagmentClose = {
    segment: {
      value: bot.alertCloseType ? 'alert' : 'preset',
      preset: {
        takeProfit: {
          isOpened: !!bot.takeProfitTargetsQuantity,
          orders: bot.takeProfitTargetsQuantity || 1,
          priceChange: bot.takeProfitPricePercent || 5,
          orderSizeScale: bot.takeProfitVolumeStepCoefficient || 1,
          orderPriceChangeScale: bot.takeProfitPriceStepCoefficient || 1,
          orderType: bot.takeProfitType.split('_')[1].toLowerCase() as IRiskManagmentClose['segment']['preset']['takeProfit']['orderType'],
        },
        stopLoss: {
          isOpened: !!bot.stopLossPercent,
          priceChange: bot.stopLossPercent || -5,
          orderType: bot.stopLossType.split('_')[1].toLowerCase() as IRiskManagmentClose['segment']['preset']['stopLoss']['orderType'],
        },
      },
      alert: {
        orderType: bot.alertCloseType as IRiskManagmentClose['segment']['alert']['orderType'],
      },
    },
  };

  return value;
};
