import { Stack } from '@mui/material';
import { Divider } from 'antd';
import { getActiveTradingPairs, getExchange, getMarket, getTradingAmount } from 'entities/choose-account';
import { getAlertSource } from 'entities/choose-source';
import { getAlertOrders, getAlertOrdersSizeScale, getIsOpened, getPresetOrders, getPresetOrdersSizeScale, getPresetPriceChange, getSegment, riskManagmentAdditionalActions } from 'entities/risk-managment-additional';
import { getAdditionalCopyValue, getPanelContent } from 'pages/trading-bots/configurator-v2/helpers';
import { getAdditionalEntries } from 'pages/trading-bots/configurator-v2/helpers/get-additional-entries';
import { getBaseOrder } from 'pages/trading-bots/configurator-v2/helpers/get-base-order';
import { getFullPosition } from 'pages/trading-bots/configurator-v2/helpers/get-full-position';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { Block } from '../../../../block';
import { INNER, WRAPPER } from '../../../styles';

const divider = (
  <Divider
    style={{
      margin: 0, 
    }}
  />
);

export const Additional = () => {
  const selectedMarket = useSelector(getMarket);

  const presition = selectedMarket.presition;
  const market = selectedMarket.code;

  const alertSource = useSelector(getAlertSource);
  const additionalSegment = useSelector(getSegment);

  const isAlert = additionalSegment === 'alert';

  const dispatch = useDispatch();

  const tradingAmount = useSelector(getTradingAmount);
  const activeTradingPairs = useSelector(getActiveTradingPairs);

  const isAdditionalOpened = useSelector(getIsOpened);
  const additionalOrders = useSelector(getPresetOrders);
  const additionalOrdersSizeScale = useSelector(getPresetOrdersSizeScale);
  const additionalOrdersPriceChangeScale = useSelector(getPresetOrdersSizeScale);
  const additionalPriceChange = useSelector(getPresetPriceChange);
  const additionalAlertOrders = useSelector(getAlertOrders);
  const additionalAlertOrdersSizeScale = useSelector(getAlertOrdersSizeScale);

  const exchange = useSelector(getExchange);
  
  const headers = useMemo(() => {
    if (!isAlert) {
      return [{
        label: '№',
        key: 'order',
      }, {
        label: 'Order size',
        key: 'order-size',
      }, {
        label: 'Price change',
        key: 'price-change',
      }];
    }

    switch (alertSource) {
    case 'indicator':
      return [{
        label: '№',
        key: 'order',
      }, {
        label: 'Order size',
        key: 'order-size',
      }, {
        label: 'Alert message',
        key: 'alert-message',
      }];
    case 'strategy':
      return [{
        label: '№',
        key: 'order',
      }, {
        label: 'Order size',
        key: 'order-size',
      }, {
        label: 'PineScript parameter',
        key: 'pineScript-parameter',
      }];
    case 'custom':
      return [{
        label: '№',
        key: 'order',
      }, {
        label: 'Order size',
        key: 'order-size',
      }, {
        label: 'JSON body',
        key: 'json-body',
      }];
    default:
      return [];
    }
  }, [alertSource, isAlert]);

  const baseOrder = useMemo(() => {
    const result = getBaseOrder({
      additional: {
        isOpened: isAdditionalOpened,
        orders: additionalOrders,
        ordersSizeScale: additionalOrdersSizeScale,
      },
      chooseAccount: {
        tradingAmount: +tradingAmount,
        activeTradingPairs: activeTradingPairs,
      },
    });

    return result;
  }, [
    isAdditionalOpened,
    additionalOrders,
    additionalOrdersSizeScale,
    tradingAmount,
    activeTradingPairs,
  ]);

  const fullPosition = useMemo(() => {
    const result = getFullPosition({
      additional: {
        isOpened: isAdditionalOpened,
        orders: additionalOrders,
        priceChange: additionalPriceChange,
        ordersSizeScale: additionalOrdersSizeScale,
        ordersPriceChangeScale: additionalOrdersPriceChangeScale,
        segment: additionalSegment,
      },
      chooseAccount: {
        tradingAmount: +tradingAmount,
        activeTradingPairs: activeTradingPairs,
      },
    });

    return result;
  }, [
    isAdditionalOpened,
    additionalOrders,
    additionalPriceChange,
    additionalOrdersSizeScale,
    additionalOrdersPriceChangeScale,
    additionalSegment,
    tradingAmount,
    activeTradingPairs,
  ]);

  const {
    formatedItems: items,
    result,
  } = useMemo(() => {
    const result = getAdditionalEntries({
      isOpened: isAdditionalOpened,
      segment: additionalSegment,
      orders: isAlert ? additionalAlertOrders : additionalOrders,
      priceChange: additionalPriceChange,
      ordersSizeScale: isAlert ? additionalAlertOrdersSizeScale : additionalOrdersSizeScale,
      ordersPriceChangeScale: additionalOrdersPriceChangeScale,
      tradingAmount: +tradingAmount,
      activeTradingPairs: activeTradingPairs,
    });

    const formatedItems = result.map((item, index) => {
      return [{
        value: item.position,
      }, {
        value: `${currencyFormatter(item.orderSize, presition)} ${market}`,
      }, {
        value: isAlert ? 'Copy' : `${item.priceChange}%`,
        copy: isAlert ? getAdditionalCopyValue({
          exchangeAccountUuid: exchange.id,
          source: alertSource,
          position: (++index) + 1,
        }) : '',
      }];
    });

    return {
      formatedItems,
      result,
    };
  }, [
    isAdditionalOpened,
    additionalOrders,
    additionalPriceChange,
    additionalOrdersSizeScale,
    additionalOrdersPriceChangeScale,
    additionalSegment,
    tradingAmount,
    activeTradingPairs,
    market,
    presition,
    alertSource,
    isAlert,
    exchange,
    additionalAlertOrders,
    additionalAlertOrdersSizeScale,
  ]);

  const error = selectedMarket.minNotional > (result?.at(-1)?.orderSize || 0) ? 'Some of additional orders are less than minimum order amount' : '';
  console.log('isError', error);

  useEffect(() => {
    dispatch(riskManagmentAdditionalActions.setError(error));
  }, [error, dispatch]);

  return (
    <Stack
      sx={{
        ...WRAPPER,
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        flex: isAlert ? 'initial' : 1,
        width: isAlert ? '100%' : 'auto',
        minWidth: {
          xs: '100%',
          md: 433,
        },
      }}
    >
      {getPanelContent({
        isAlert,
        source: alertSource,
        url: `/videos/additional-${alertSource}.mp4`,
      })}

      <Stack sx={INNER}>
        <Block
          title='Entry order'
          layout='row'
          content={[{
            title: 'Open order size',
            value: `${currencyFormatter(baseOrder, presition)} ${market}`,
          }]}
        />

        {divider}

        <Block
          title='Additional entry orders'
          layout='table'
          error={error}
          content={{
            headers: headers,
            items: items as any,
          }}
        />

        {divider}

        <Block
          layout='row'
          content={[{
            title: 'Full position per coin',
            value: `${currencyFormatter(fullPosition, presition)} ${market}`,
          }]}
        />
      </Stack>
    </Stack>
  );
};
