import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  gap: '24px',
  width: '100%',
  maxWidth: {
    xs: '100%',
    md: 365,
  },
  alignSelf: 'center',
};
