import { SxProps, Theme } from '@mui/material';

export const TABLE_HEADER: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&>h1': {
    fontSize: 10,
    fontWeight: 400,
    color: '#3A3A3C',
    display: 'flex',
    flex: 1,
  },
};
