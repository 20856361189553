import { SxProps, Theme } from '@mui/material';

export const tip: SxProps<Theme> = {
  // padding: '5px 4px',
  // borderRadius: 1,
  // backgroundColor: '#F2F2F2',
  // fontSize: 9,
  // fontWeight: 500,
  // maxWidth: 'max-content',
  width: 16,
  height: 16,
  '&>svg': {
    width: '100%',
    height: '100%',
  },
};
